import authService from '../../../services/auth'
import { USER_STATUS } from '../../../misc/commons'
import ChangePassword from './change-pw'
import {getUserType } from '../../../misc/auth'
export default {
    components: {
        ChangePassword
    },
    data() {
        return {
            user_status: USER_STATUS,
            user: {
                email: '',
                fname: '',
                lname: '',
                full_name: '',
                title: '',
                location: '',
                phone: '',
                photo: '',
                status: '',
                org_name: '',
                role: '',
                org_id: 0
            },
            Usertype: getUserType(),
            Org_type: '',
            changePasswordKey: 0,
            showChangePassword: true
        }
    },
    mounted() {
        this.fetchProfile()
        if(this.$store.state.organization.isSSO)
        {
            this.showChangePassword = false
        }
    },
    methods: {
        editProfile() {
            this.$router.push({name: 'Edit Account'})
        },
        acceptNumber() {
            var x = this.user.phone.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
            this.user.phone = (x[1] ? x[1] + '-' : '') + x[2] + (x[3] ? '-' + x[3] : '')
        },
        fetchProfile() {
            authService.getUserProfile().then(res => {
                if (!res.error) {
                    this.user = res.data.d
                    this.acceptNumber()
                    this.fetchOrgById(this.user.org_id)
                }
            })
        },
        fetchOrgById(id) {
            authService.GetOrganizationById(id).then(res => {
                if (!res.error) {
                    this.Org_type = res.data.d.type
                }
            })
        },
        openPasswordModal (){
            this.changePasswordKey += 1
            this.$nextTick(()=>{
                this.$refs.changePassword.forceRerender()
            })
        }
    }
}